export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [0,2,3,4,5,6,7,9,10];

export const dictionary = {
		"/": [~12],
		"/app": [~13,[2]],
		"/app/calendar": [14,[2]],
		"/app/changeOffice": [15,[2]],
		"/app/dashboard": [~16,[2]],
		"/app/events": [~17,[2]],
		"/app/events/[eventName]": [18,[2,3]],
		"/app/events/[eventName]/[occuranceId]/checkin": [19,[2,3,4]],
		"/app/integrations": [20,[2,5]],
		"/app/marketing": [~21,[2]],
		"/app/marketing/[id]/email": [22,[2,6]],
		"/app/people": [~23,[2,7]],
		"/app/preferences": [24,[2]],
		"/auth": [25,[8]],
		"/auth/forgot": [~26,[8]],
		"/auth/invite": [27,[8]],
		"/auth/login": [~28,[8]],
		"/auth/register": [~29,[8]],
		"/auth/reset": [~30,[8]],
		"/completedPayment": [31],
		"/events/[office]": [32,[9]],
		"/events/[office]/[event]": [33,[9,10]],
		"/events/[office]/[event]/[date]": [~34,[9,10]],
		"/events/[office]/[event]/[date]/payments": [~35,[9,10]],
		"/public": [36,[11]],
		"/public/auditions": [37,[11]],
		"/public/performances": [38,[11]],
		"/success": [39]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';